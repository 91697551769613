import React, { useState, useEffect, useCallback } from 'react'
import { Loading } from 'components/Loading'
import { useMappedState } from 'redux-react-hook'
import { selectorLogin } from 'store/login'

const withAuthentication = Page => {
  const PageComponent = props => {
    const [isLoading, setIsLoading] = useState(true)
    const [isNavigating, setIsNavigating] = useState(false)
    const { loggedIn } = useMappedState(useCallback(selectorLogin))

    useEffect(() => {
      if (!loggedIn) {
        window.location.href = '/login'
        setIsNavigating(true)
      }

      setIsLoading(false)
    }, [loggedIn])

    return (
      <section>
        {isLoading || isNavigating ? <Loading /> : <Page {...props} />}
      </section>
    )
  }
  PageComponent.displayName = 'PageComponent'

  return PageComponent
}

withAuthentication.displayName = 'withAuthentication'

export default withAuthentication
